'use strict'

import './lib/polyfills'
import './lib/webp'
import 'focus-visible'

import conditioner, {
    connection,
    idle,
    saveData,
    screen,
    visible
} from '@parallaxagency/conditioner'
import screens from './tailwind.screens'
import { detect } from 'detect-browser'

conditioner.addPlugin({
    moduleSetName: name => `./components/${name}.js`,
    moduleGetConstructor: module => module.default,
    moduleImport: name =>
        import(/* webpackChunkName: "conditionerPlugin" */ `${name}`)
})

conditioner.addPlugin(connection)
conditioner.addPlugin(idle)
conditioner.addPlugin(saveData)
conditioner.addPlugin(screen(screens))
conditioner.addPlugin(visible)

conditioner.hydrate(document.documentElement)

const browser = detect()
if (browser && browser.name) {
    document.body.classList.add(browser.name)
}
